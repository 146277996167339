import { Suspense, lazy, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Dialog from '../components/ConfirmDailog/Dialog';
import { RootState, useAppDispatch } from '../store';
import { logoutFormApplication, setHeaderDetails } from '../store/slice';
import ProtectedRoute from './PrivateRoute';

const Layout = lazy(() => import('../components/Layout/Layout'));
const RenderControls = lazy(() => import('../components/controls/RenderControls'));
const Download = lazy(() => import('../pages/Download/Download'));
const ExpiredToken = lazy(() => import('../pages/Erorrs/ExpiredToken/ExpiredToken'));
const InvalidToken = lazy(() => import('../pages/Erorrs/InvalidToken/InvalidToken'));
const PageNotFound = lazy(() => import('../pages/Erorrs/PageNotFound/PageNotFound'));
const SessionExpired = lazy(() => import('../pages/Erorrs/SessionExpired/SessionExpired'));
const Firma = lazy(() => import('../pages/Firma/Firma'));
const Login = lazy(() => import('../pages/Login/Login'));
const Questionario = lazy(() => import('../pages/Questionario/Questionario'));
const SelfId = lazy(() => import('../pages/SelfId/SelfId'));
const Tracking = lazy(() => import('../pages/Tracking/Tracking'));
const Upload = lazy(() => import('../pages/Upload/Upload'));
const Welcome = lazy(() => import('../pages/Welcome/Welcome'));

const AppRoutes = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const sideBarDetail = useSelector((state: RootState) => state.layout?.sideBarDetail);
	const loginDetatails = useSelector((state: RootState) => state.login.loginDetails?.data);

	const location = useLocation();
	const pathname = location.pathname;
	const [showModal, setShowModal] = useState<boolean>(false);
	const isDirectLogin = sessionStorage.getItem('isDirectLogin');

	useEffect(() => {
		if (sideBarDetail.length !== 0) {
			const welcomeModule = sideBarDetail.filter(
				e => e.mainTitle?.toLowerCase() === 'welcome',
			);
			if (location.pathname === '/welcome' && welcomeModule.length === 0) {
				dispatch(setHeaderDetails(sideBarDetail[0]?.title ?? ''));
				navigate(`/${sideBarDetail[0]?.type?.toLowerCase()}`);
			}
		}
	}, [sideBarDetail]);

	useMemo(() => {
		if (sideBarDetail.length !== 0) {
			const currentModule = pathname.split('/')[1];
			const currentModuleIndex = sideBarDetail.findIndex(
				element => element.type?.toLowerCase() === currentModule,
			);
			if (sideBarDetail[currentModuleIndex]?.isHidden) {
				const nextIndex = currentModuleIndex + 1;
				dispatch(setHeaderDetails(sideBarDetail[nextIndex]?.title ?? ''));
				navigate(`/${sideBarDetail[nextIndex]?.type?.toLowerCase()}`);
			} else {
				dispatch(setHeaderDetails(sideBarDetail[currentModuleIndex]?.title ?? ''));
			}
		}
	}, [pathname, sideBarDetail]);

	useEffect(() => {
		if (loginDetatails?.isLoggIn && pathname.includes('accessToken')) {
			setShowModal(true);
		} else if (
			loginDetatails?.isLoggIn &&
			(pathname.includes('invalidAccessToken') ||
				pathname.includes('expiredAccessToken') ||
				pathname.includes('sessionExpired'))
		) {
			dispatch(logoutFormApplication());
		}
	}, [location]);

	const handleStay = () => {
		if (loginDetatails?.isLoggIn) {
			if (loginDetatails?.loginUser?.lastOpenedModule) {
				window.location.href = `/${loginDetatails?.loginUser?.lastOpenedModule
					.toLocaleLowerCase()
					.replaceAll(' ', '-')
					.trim()}`;
			} else navigate(-1);
		}
	};

	return (
		<div>
			{showModal ? (
				<Dialog
					actionButtons={false}
					// okButton="Stay"
					// cancelButton="Leave"
					// okClickHandler={handleStay}
					// cancelClickHandler={handleLeave}
				>
					<div className="mt-3 sm:ml-4 sm:mt-0 sm:text-left">
						<h3
							className="text-base font-semibold leading-6 text-gray-900"
							id="modal-title">
							Confirmation
						</h3>
						<div className="mt-2">
							<p className="text-sm text-gray-500">
								Are you sure you want to move out from last session?
							</p>
						</div>
						<div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
							<button
								type="button"
								className="inline-flex w-full bg-brand-secondary-color justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-secondary-color sm:ml-3 sm:w-auto uppercase"
								onClick={handleStay}>
								Stay
							</button>
							<button
								type="button"
								className={`mt-3 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 sm:mt-0 sm:w-auto uppercase ${
									isDirectLogin == 'true'
										? 'bg-gray-50 text-idos-gray-color-medium'
										: ' bg-white text-brand-secondary-color'
								}`}
								disabled={isDirectLogin == 'true'}
								onClick={() => {
									fetch('/api/base/logout').then(() => {
										window.location.href = pathname;
									});
								}}>
								Leave
							</button>
						</div>
					</div>
				</Dialog>
			) : (
				<Suspense fallback={''}>
					<Routes>
						<Route path="/accessToken/:token" element={<Login />} />
						<Route path="/invalidAccessToken" element={<InvalidToken />} />
						<Route path="/expiredAccessToken" element={<ExpiredToken />} />
						<Route path="/sessionExpired" element={<SessionExpired />} />
						<Route element={<ProtectedRoute />}>
							<Route
								path="/"
								element={
									<Suspense fallback={''}>
										<Layout />
									</Suspense>
								}>
								<Route
									path="welcome"
									element={
										<Suspense fallback={''}>
											<Welcome />
										</Suspense>
									}></Route>
								<Route
									path="tracking"
									element={
										<Suspense fallback={''}>
											<Tracking />
										</Suspense>
									}></Route>
								<Route
									path="download"
									element={
										<Suspense fallback={''}>
											<Download />
										</Suspense>
									}></Route>
								<Route
									path="upload"
									element={
										<Suspense fallback={''}>
											<Upload />
										</Suspense>
									}></Route>
								<Route
									path="identification"
									element={
										<Suspense fallback={''}>
											<SelfId />
										</Suspense>
									}></Route>
								<Route
									path="Survey"
									element={
										<Suspense fallback={''}>
											<Questionario />
										</Suspense>
									}></Route>
								<Route
									path="signature"
									element={
										<Suspense fallback={''}>
											<Firma />
										</Suspense>
									}></Route>
								<Route
									path="rendermodules"
									element={
										<Suspense fallback={''}>
											<RenderControls />
										</Suspense>
									}></Route>
							</Route>
						</Route>
						<Route path="/" element={<Navigate to="/Welcome" replace />} />
						<Route
							path="*"
							element={
								<Suspense fallback={''}>
									<PageNotFound />
								</Suspense>
							}
						/>
					</Routes>
				</Suspense>
			)}
		</div>
	);
};

export default AppRoutes;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { createBlob } from '../../../components/utilities/UtilityFunctionsComponent';
import {
	fetchSelfIdPageDetails,
	getQRCodeDetailsAction,
	selfIdFileDetailsAction,
	sendIdentificationDataToSource,
	uploadPersonalDetails,
} from './selfId.actions';
import { selfIdPageDetails } from './selfId.initialState';
import {
	IdentificationSavedData,
	RecordedVideoData,
	SelectedDocument,
	SelfIdModel,
	SelfieData,
} from './selfId.type';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const updateSelfIdDState = (state: WritableDraft<SelfIdModel>, action: PayloadAction<any>) => {
	const identificationSavedData = action.payload.data;
	state.identification = identificationSavedData;
	if (identificationSavedData.personalDetailsFileData.length > 0) {
		state.personalDetailsSaved = true;
	} else {
		state.personalDetailsSaved = false;
	}
	if (identificationSavedData.frontAndBackPhoto?.documents.length > 0) {
		state.selectedDocumentId = identificationSavedData.frontAndBackPhoto.selectedDocument;
		state.selectedDocument =
			identificationSavedData.frontAndBackPhoto?.documents[0].documentName;
		if (identificationSavedData.isRecycle) {
			state.backRecycle =
				!identificationSavedData.frontAndBackPhoto?.documents[0].isFileRecycled;
			state.frontRecycle =
				!identificationSavedData.frontAndBackPhoto?.documents[0].isFileRecycled;
		}
		if (identificationSavedData.frontAndBackPhoto?.documents[0]?.fileDetail.length >= 1) {
			state.frontImageFilePath =
				identificationSavedData.frontAndBackPhoto.documents[0].fileDetail[0].minoFileLocation!;
			state.frontImage = `data:image/jpeg;base64, ${identificationSavedData.frontAndBackPhoto
				.documents[0].fileDetail[0].fileBase64!}`;
		}

		if (identificationSavedData.frontAndBackPhoto?.documents[0]?.fileDetail.length >= 2) {
			state.backImageFilePath =
				identificationSavedData.frontAndBackPhoto.documents[0].fileDetail[1].minoFileLocation!;
			state.backImage = `data:image/jpeg;base64, ${identificationSavedData.frontAndBackPhoto
				.documents[0].fileDetail[1].fileBase64!}`;
		}
	}
	if (identificationSavedData.selfieSetting?.documents.length > 0) {
		if (identificationSavedData.isRecycle) {
			state.selfieRecycle =
				!identificationSavedData.selfieSetting?.documents[0].isFileRecycled;
		}
		if (identificationSavedData.selfieSetting?.documents[0]?.fileDetail.length >= 1) {
			state.selfieFilepath =
				identificationSavedData.selfieSetting.documents[0].fileDetail[0].minoFileLocation!;
			state.selfie = `data:image/jpeg;base64, ${identificationSavedData.selfieSetting
				.documents[0].fileDetail[0].fileBase64!}`;
			state.selfieId = identificationSavedData.selfieSetting.selectedDocumentCode;
		}
	}

	if (identificationSavedData.videoSetting.documents.length > 0) {
		state.videoFilepath =
			identificationSavedData.videoSetting.documents[0].fileDetail[0].minoFileLocation!;
		if (identificationSavedData.videoSetting.documents[0].fileDetail[0].fileBase64) {
			const blob = createBlob(
				identificationSavedData.videoSetting.documents[0].fileDetail[0].fileBase64! ?? '',
				identificationSavedData.videoSetting.documents[0].fileDetail[0].fileType!,
			);
			state.recordedVideo = URL.createObjectURL(blob);
		} else {
			state.recordedVideo = 'Not';
		}

		state.videoRecycle =
			identificationSavedData.isRecycle &&
			!identificationSavedData.videoSetting.documents[0].isFileRecycled;

		state.recordedVideoId = identificationSavedData.videoSetting.selectedDocumentCode;
	}

	if (identificationSavedData.isRecycle) {
		if (identificationSavedData.personalDetailsFileData.length > 0) {
			state.personalDetailsRecycle =
				!identificationSavedData.personalDetailsFileData[0].isFileRecycled;
		}
	}
	return state;
};

export const selfIdSlice = createSlice({
	name: 'selfIdPage',
	initialState: selfIdPageDetails,
	reducers: {
		resetSelfIdState: () => selfIdPageDetails,
		setTotalSteps: (state: SelfIdModel, action: PayloadAction<number>) => {
			state.totalSteps = action.payload;
		},
		setSelectedDocument(state: SelfIdModel, action: PayloadAction<SelectedDocument>) {
			state.selectedDocument = action.payload.selectedDocument;
			state.selectedDocumentId = action.payload.selectedDocumentId;
			return state;
		},
		setNewlySelectedDocument(state: SelfIdModel, action: PayloadAction<SelectedDocument>) {
			state.newlySelectedDocument = action.payload.selectedDocument;
			state.newlySelectedDocumentId = action.payload.selectedDocumentId;
			return state;
		},
		setRecordedVideo(state: SelfIdModel, action: PayloadAction<RecordedVideoData>) {
			state.recordedVideo = action.payload.recordedVideo;
			state.recordedVideoId = action.payload.recordedVideoId;
			if (state.identification?.isRecycle) {
				state.videoRecycle = false;
			}
			return state;
		},
		setFrontAndBackPhotoRequired: (state: SelfIdModel) => {
			const identification: IdentificationSavedData = JSON.parse(
				JSON.stringify(state.identification),
			);
			if (identification) {
				identification.backPhoto = true;
				identification.frontPhoto = true;
				state.identification = identification;
			}
			return state;
		},
		setFrontPhoto(state: SelfIdModel, action: PayloadAction<string>) {
			state.frontImage = action.payload;
			if (state.identification?.isRecycle) {
				state.frontRecycle = false;
			}
			return state;
		},
		setBackPhoto(state: SelfIdModel, action: PayloadAction<string>) {
			state.backImage = action.payload;
			if (state.identification?.isRecycle) {
				state.backRecycle = false;
			}
			return state;
		},
		setRandomPhrase(state: SelfIdModel, action: PayloadAction<string>) {
			state.randomPhrase = action.payload;
			return state;
		},
		setUpdateDocType(state: SelfIdModel, action: PayloadAction<string>) {
			state.docTypeToUpdate = action.payload;
			return state;
		},
		setComingFromQRCode: (state: SelfIdModel, action: PayloadAction<boolean>) => {
			state.comingFromQRCode = action.payload;
		},
		setSelfie(state: SelfIdModel, action: PayloadAction<SelfieData>) {
			state.selfie = action.payload.selfie;
			state.selfieId = action.payload.selfieId;
			if (state.identification?.isRecycle) {
				state.selfieRecycle = false;
			}
			return state;
		},
	},
	extraReducers(builder) {
		builder.addCase(uploadPersonalDetails.fulfilled, updateSelfIdDState);
		builder.addCase(fetchSelfIdPageDetails.fulfilled, (state, action) => {
			const identificationSavedData = action.payload;
			state.identification = identificationSavedData;
			if (identificationSavedData.personalDetailsFileData.length > 0) {
				state.personalDetailsSaved = true;
			} else {
				state.personalDetailsSaved = false;
			}
			if (identificationSavedData.frontAndBackPhoto?.documents.length > 0) {
				state.selectedDocumentId =
					identificationSavedData.frontAndBackPhoto.selectedDocument;
				state.selectedDocument =
					identificationSavedData.frontAndBackPhoto?.documents[0].documentName;
				if (identificationSavedData.isRecycle) {
					state.backRecycle =
						!identificationSavedData.frontAndBackPhoto?.documents[0].isFileRecycled;
					state.frontRecycle =
						!identificationSavedData.frontAndBackPhoto?.documents[0].isFileRecycled;
				}
				if (
					identificationSavedData.frontAndBackPhoto?.documents[0]?.fileDetail.length >= 1
				) {
					state.frontImageFilePath =
						identificationSavedData.frontAndBackPhoto.documents[0].fileDetail[0].minoFileLocation!;
					state.frontImage = `data:image/jpeg;base64, ${identificationSavedData
						.frontAndBackPhoto.documents[0].fileDetail[0].fileBase64!}`;
				}

				if (
					identificationSavedData.frontAndBackPhoto?.documents[0]?.fileDetail.length >= 2
				) {
					state.backImageFilePath =
						identificationSavedData.frontAndBackPhoto.documents[0].fileDetail[1].minoFileLocation!;
					state.backImage = `data:image/jpeg;base64, ${identificationSavedData
						.frontAndBackPhoto.documents[0].fileDetail[1].fileBase64!}`;
				}
			}

			if (identificationSavedData.selfieSetting?.documents.length > 0) {
				if (identificationSavedData.isRecycle) {
					state.selfieRecycle =
						!identificationSavedData.selfieSetting?.documents[0].isFileRecycled;
				}
				if (identificationSavedData.selfieSetting?.documents[0]?.fileDetail.length >= 1) {
					state.selfieFilepath =
						identificationSavedData.selfieSetting.documents[0].fileDetail[0].minoFileLocation!;
					state.selfie = `data:image/jpeg;base64, ${identificationSavedData.selfieSetting
						.documents[0].fileDetail[0].fileBase64!}`;
					state.selfieId = identificationSavedData.selfieSetting.selectedDocumentCode;
				}
			}

			if (identificationSavedData.videoSetting.documents.length > 0) {
				state.videoFilepath =
					identificationSavedData.videoSetting.documents[0].fileDetail[0].minoFileLocation!;
				if (identificationSavedData.videoSetting.documents[0].fileDetail[0].fileBase64) {
					const blob = createBlob(
						identificationSavedData.videoSetting.documents[0].fileDetail[0]
							.fileBase64! ?? '',
						identificationSavedData.videoSetting.documents[0].fileDetail[0].fileType!,
					);
					state.recordedVideo = URL.createObjectURL(blob);
				} else {
					state.recordedVideo = 'Not';
				}

				state.videoRecycle =
					identificationSavedData.isRecycle &&
					!identificationSavedData.videoSetting.documents[0].isFileRecycled;

				state.recordedVideoId = identificationSavedData.videoSetting.selectedDocumentCode;
			}

			state.personalDetailsRecycle =
				identificationSavedData.isRecycle &&
				identificationSavedData.personalDetailsFileData.length > 0 &&
				!identificationSavedData.personalDetailsFileData[0].isFileRecycled;
			return state;
		});
		builder.addCase(selfIdFileDetailsAction.fulfilled, updateSelfIdDState);
		builder.addCase(sendIdentificationDataToSource.fulfilled, updateSelfIdDState);
		builder.addCase(getQRCodeDetailsAction.fulfilled, (state, action) => {
			state.qrCodeDetails.url = action.payload.url;
		});
	},
});

export const { reducer: selfIdReducer } = selfIdSlice;
export const {
	setSelectedDocument,
	setRecordedVideo,
	setFrontPhoto,
	setBackPhoto,
	setRandomPhrase,
	setUpdateDocType,
	setNewlySelectedDocument,
	resetSelfIdState,
	setFrontAndBackPhotoRequired,
	setTotalSteps,
	setComingFromQRCode,
	setSelfie,
} = selfIdSlice.actions;
